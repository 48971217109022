import React, {useState} from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import './style.css';
import {BsCart4} from 'react-icons/bs';

const Header = (props) => {
  const [open, setOpen] = useState(false);
  const toggleNav = () => {
    setOpen(!open);
    const navElement = document.getElementById('myCartnav');
    navElement.style.width = open ? '0' : '100%';
  };
  const toggleHumberg = () => {
    setOpen(!open);
    const humbergElement = document.getElementById('mySidenav');
    humbergElement.style.width = open ? '0' : '100%';
  };
  return (
    <div>
      <Navbar
        expand="sm"
        collapseOnSelect
        bg="dark"
        variant="dark"
        className="header-navbar"
        fixed="top"
      >
        <Nav
          className=" header-nav"
          // style={{ width: "100%" }}
        >
          <span
            id={props.mySidenav}
            className="hamburder-icon"
            onClick={toggleHumberg}
          >
            &#9776;
          </span>
          &nbsp;
        </Nav>

        <Nav
          className=" header-nav"
          // style={{ width: "100%" }}
        >
          <BsCart4
            id={props.myCartnav}
            onClick={toggleNav}
            className="text-white"
          />
          <span className="badge text-white">{props?.filterData.length}</span>
          &nbsp;
        </Nav>
      </Navbar>
    </div>
  );
};

export default Header;
