import React, {useEffect, useState} from 'react';
import {API, Storage, Auth} from 'aws-amplify';
import {Row, Col, Card, Modal, Button, Form} from 'react-bootstrap';
import {CircularProgress} from '@mui/material';
import {BsXLg} from 'react-icons/bs';
import {useLocation, useParams} from 'react-router';
import './style.css';
import {toast} from 'react-toastify';
const generateRandomString = () => Math.random().toString(36).substring(2, 12);
const AddToCart = ({
  filterData,
  jsonData,
  setJsonData,
  totalPrice,
  setCartItem,
  setTotalPrice,
}) => {
  const {state} = useLocation();
  const [filterCart, setFilterCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [totalCartItemPrice, setTotalCartItemPrice] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = (id, price, count) => {
    const newArr = filterCart.filter((item) => item.id !== id);
    const index = jsonData.findIndex((item) => item.id === id);
    jsonData[index].count = jsonData[index].count = 0;

    setJsonData(jsonData);
    const newVal = totalCartItemPrice - price * count;
    setTotalPrice(newVal);
    setFilterCart(newArr);
    setCartItem(newArr);
  };
  function closeNav() {
    alert();
    document.getElementById('myCartnav').style.width = '0';
  }
  useEffect(() => {
    setTotalCartItemPrice(totalPrice);
    setFilterCart(filterData);
  }, [totalPrice]);

  function roundToNearest50(num) {
    const nearest10 = Math.ceil(num / 10) * 10;
    return nearest10;
  }

  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUsername(user.username);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowModal(false);
    setLoading(true);
    let total = 0;
    let productsOutput = '';

    await filterCart.forEach((product) => {
      const requestBody = {
        quantity: product.count,
      };
      API.put('product', `/product/updateProduct/${product.id}`, {
        body: requestBody,
      });

      const totalPrice = product.count * product.price;
      total += totalPrice;
      productsOutput += `${product.count} x ${
        product.title
      } = $${totalPrice.toFixed(2)}<br>`;
    });

    const output = `Hi! There is a new order from user ${username}! <br>User ${username} ordered ${
      filterCart.length
    } products:<br>${productsOutput} The grant Total is : $${total}<br>${
      comments
        ? 'Comments from user : ' + comments
        : 'User did not add a comment'
    }`;
    const data = {
      id: generateRandomString(),
      message: output,
    };
    const response = await API.post('order', '/order', {body: data});
    console.log('order response', response);

    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 5000);
    toast.success('Order created successfuly');
    setLoading(false);
    setFilterCart([]);
  };
  return (
    // console.log(
    //   "This is total price",
    //   state?.props?.filterData.reduce((n, { price }) => n + price, 0)
    // ),
    <>
      <div id="myCartnav" class="cartnav">
        <div className="row">
          <Col md={12} lg={12} className="">
            <h3 className="my-4 mx-3 text-white">My selection</h3>
            <table class="table table-dark">
              <thead className="text-white">
                <tr>
                  <th scope="col">Product Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="text-white" style={{textAlign: 'center'}}>
                {filterCart.length > 0 ? (
                  filterCart?.map((data, key) => {
                    return (
                      <tr key={data.id}>
                        <td style={{textAlign: 'center'}}>{data?.title}</td>
                        <td style={{textAlign: 'center'}}>{data?.count}</td>
                        <td style={{textAlign: 'center'}}>
                          {data?.price * data?.count + '$'}
                        </td>
                        <td>
                          <BsXLg
                            onClick={() =>
                              handleDelete(data?.id, data.price, data.count)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="text-center mt-3">
                        <strong>{'No bottles selected yet!'}</strong>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="float-right mx-3 ">
              <div>
                <p className="ms-auto mt-3 text-white">
                  Total Amount:{' '}
                  {filterCart.length == 0 ? 0 : totalCartItemPrice + '$'}{' '}
                </p>
                <p className="ms-auto mt-3 text-white">
                  Total Suggested:{' '}
                  {filterCart.length === 0
                    ? 0
                    : roundToNearest50(totalCartItemPrice) + '$'}{' '}
                </p>
              </div>
              <div>
                <button
                  className="btn btn-primary  mb-3"
                  onClick={handleOpenModal}
                  disabled={filterCart.length === 0}
                >
                  Send your selection{' '}
                  {filterCart.length === 0
                    ? 0
                    : roundToNearest50(totalCartItemPrice) + '$'}
                </button>
                {loading && (
                  <CircularProgress
                    color="secondary"
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-6px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add a comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form to input comments */}
          <Form.Group controlId="comments">
            <Form.Label>Comment:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddToCart;
