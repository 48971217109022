import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import Swal from 'sweetalert2';
import TableBody from '@mui/material/TableBody';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import {API, Storage} from 'aws-amplify';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {toast} from 'react-toastify';

import DialogTitle from '@mui/material/DialogTitle';
import {
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';

const generateRandomString = () => Math.random().toString(36).substring(2, 12);

const defaultFormData = {
  image: null,
  count: 0,
  title: '',
  category: '',
  size: '',
  description: '',
  quantity: 0,
  country: '',
  mevushal: '',
  price: 0,
  region: '',
};

export default function Product() {
  const [products, setProduct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataProduct = await API.get('product', '/product');
        setProduct(dataProduct.data);
        console.log(dataProduct.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [!open, !editOpen]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
      filename: filename,
    }));
  };

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {image, filename, quantity, price, ...rest} = formData;

    try {
      const generatedFilename = generateRandomString() + '_' + filename;
      await Storage.put(generatedFilename, image);

      const data = {
        image: generatedFilename,
        id: generateRandomString(),
        ...rest,
        quantity: parseInt(quantity),
        price: parseFloat(price),
      };

      const response = await API.post('product', '/product', {body: data});
      toast.success('Product created successfuly');
      setFormData(defaultFormData);
      setOpen(false);
      console.log(response);
    } catch (error) {
      toast.error('Error while creating Product!');
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const {id, filename, quantity, price, ...rest} = formData;

    try {
      const data = {
        ...rest,
        quantity: parseInt(quantity),
        price: parseFloat(price),
      };

      const response = await API.put('product', `/product/${id}`, {body: data});
      toast.success('Product Edited successfuly');
      setFormData(defaultFormData);
      setEditOpen(false);
      console.log(response);
    } catch (error) {
      toast.error('Error while Editing Product!');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = async (id) => {
    const productData = await API.get('product', `/product/getProduct/${id}`);
    setFormData(productData);
    setEditOpen(true);
  };
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await API.del('product', `/product/${id}`);
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }, 1000);
        toast.success('Product deleted successfully');
      } catch (error) {
        console.error('Error deleting product:', error);
        toast.error('Failed to delete product');
      }
    }
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>
        <SpeedDialIcon />
      </Button>
      <br />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Add new Product'}</DialogTitle>
        <DialogContent>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '400px',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#f8f8f8',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              gap: '16px',
            }}
            onSubmit={handleSubmit}
          >
            <input
              type="file"
              label="Image"
              name="image"
              onChange={handleFileChange}
              required
            />

            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />

              <TextField
                label="Category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              />
            </div>

            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              required
            />

            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />

              <TextField
                label="Region"
                name="region"
                value={formData.region}
                onChange={handleChange}
              />
            </div>

            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Quantity"
                name="quantity"
                type="number"
                value={formData.quantity}
                onChange={handleChange}
                required
              />

              <TextField
                label="Price"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                required
              />
              <TextField
                label="Size"
                name="size"
                value={formData.size}
                onChange={handleChange}
                required
              />
            </div>
            <FormControl component="fieldset">
              <FormLabel component="legend">mevushal</FormLabel>
              <RadioGroup
                name="mevushal"
                value={formData.mevushal}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>

            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Edit Product'}</DialogTitle>
        <DialogContent>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '400px',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#f8f8f8',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              gap: '16px',
            }}
            onSubmit={handleEditSubmit}
          >
            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />

              <TextField
                label="Category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              />
            </div>

            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              required
            />

            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />

              <TextField
                label="Region"
                name="region"
                value={formData.region}
                onChange={handleChange}
              />
            </div>

            <div style={{display: 'flex', gap: '16px'}}>
              <TextField
                label="Quantity"
                name="quantity"
                type="number"
                value={formData.quantity}
                onChange={handleChange}
                required
              />

              <TextField
                label="Price"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                required
              />
              <TextField
                label="Size"
                name="size"
                value={formData.size}
                onChange={handleChange}
                required
              />
            </div>
            <FormControl component="fieldset">
              <FormLabel component="legend">mevushal</FormLabel>
              <RadioGroup
                name="mevushal"
                value={formData.mevushal}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Edit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image URL</TableCell>
              <TableCell align="right">Title</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">quantity&nbsp;(Stock)</TableCell>
              <TableCell align="right">Country&nbsp;</TableCell>
              <TableCell align="right">Region&nbsp;</TableCell>
              <TableCell align="right">Mevushal&nbsp;</TableCell>
              <TableCell align="right">Price&nbsp;</TableCell>
              <TableCell align="right">Size&nbsp;</TableCell>
              <TableCell align="right">Edit&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow
                key={product.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {product.image}
                </TableCell>
                <TableCell align="right">{product.title}</TableCell>
                <TableCell align="right">{product.description}</TableCell>
                <TableCell align="right">{product.category}</TableCell>
                <TableCell align="right">{product.quantity}</TableCell>
                <TableCell align="right">{product.country}</TableCell>
                <TableCell align="right">{product.region}</TableCell>
                <TableCell align="right">{product.mevushal}</TableCell>
                <TableCell align="right">{product.price}</TableCell>
                <TableCell align="right">{product.size}</TableCell>
                <TableCell align="right">
                  {' '}
                  <Button onClick={() => handleEditOpen(product.id)}>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(product.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
