import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {API} from 'aws-amplify';
import Container from 'react-bootstrap/Container';

const Sidebar = (props) => {
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataProduct = await API.get('product', '/product');
        setJsonData(dataProduct.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  let data = jsonData.map(({category, country, size}) => ({
    category,
    country,
    size,
  }));

  const seen = new Set();

  data.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const pair = `${key}-${value}`;
      if (seen.has(pair)) {
        delete obj[key];
      } else {
        seen.add(pair);
        console.log('seen', seen);
      }
    });
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCheckboxChange = (event) => {
    const {name, value, checked} = event.target;

    props.handleFilter(name, value, checked);
  };

  return (
    <>
      <div className="row">
        <Col md={12} lg={12} xs={12}>
          <Container>
            <div id="mySidenav" className="sidenav my-4">
              <ul>
                {jsonData.length > 0 &&
                  Object.keys(data[0]).map((key) => {
                    if (
                      jsonData.some(
                        (item) => item.hasOwnProperty(key) && item[key]
                      )
                    ) {
                      return (
                        <React.Fragment key={key}>
                          <label className="text-white">
                            {capitalizeFirstLetter(key)}
                          </label>
                          <ul>
                            {data.map(
                              (item, index) =>
                                item.hasOwnProperty(key) &&
                                item[key] && (
                                  <li key={index}>
                                    <input
                                      type="checkbox"
                                      name={key}
                                      onChange={handleCheckboxChange}
                                      value={item[key]}
                                      style={{marginRight: '5px'}}
                                    />
                                    {item[key]}
                                  </li>
                                )
                            )}
                          </ul>
                          <br />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
              </ul>

              <div className="type-container">
                <label className="text-white">Type</label>
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      name="mevushal"
                      onChange={handleCheckboxChange}
                      value={'true'}
                      style={{marginRight: '5px'}}
                    />{' '}
                    Mevushal
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="mevushal"
                      onChange={handleCheckboxChange}
                      value={'false'}
                      style={{marginRight: '5px'}}
                    />{' '}
                    Not Mevushal
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </Col>
      </div>
    </>
  );
};

export default Sidebar;
