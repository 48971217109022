import React from 'react';
import './App.css';
import {Authenticator} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import {Route, Routes} from 'react-router';
import Home from '../src/pages/Home';
import AddToCart from './pages/addToCart';
import Admin from './pages/Admin';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsconfig);

function App() {
  return (
    <>
      <div>
        <Authenticator hideSignUp>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/cart" element={<AddToCart />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
          </Routes>
        </Authenticator>
        <style>
          {`
          [data-amplify-footer ] {
            display: none !important;
          }
          .amplify-label {
            display: none !important;
          }
         [data-amplify-authenticator] [data-amplify-router] {
            background-color: black;
            border : 0px;
            margin-top : 200px
          }
        .amplify-input {
          color: white;
        }
        
          .amplify-button--primary {
            background-color: hsl(190deg 5.31% 1.44%);
          }
         
         
        `}
        </style>
      </div>
    </>
  );
}

export default App;
