import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {toast} from 'react-toastify';

import {TextField, Button} from '@mui/material';

const defaultFormData = {
  username: '',
  password: '',
  email: '',
  name: '',
};

export default function User() {
  const [formData, setFormData] = useState(defaultFormData);

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await Auth.signUp({
        username: formData.username,
        password: formData.password,
        attributes: {
          email: formData.email,
          name: formData.name,
        },
        validationData: [],
        clientMetadata: {messageAction: 'SUPPRESS'},
      });

      toast.success('User created successfuly');
      setFormData(defaultFormData);
    } catch (error) {
      toast.error('Error while creating user!');
      console.log('error', error);
    }
  };

  return (
    <>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '400px',
          margin: '0 auto',
          padding: '20px',
          backgroundColor: '#f8f8f8',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          gap: '16px',
        }}
        onSubmit={handleSubmit}
      >
        <div style={{display: 'flex', gap: '16px'}}>
          <TextField
            label="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />

          <TextField
            label="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            type="password"
            required
          />
        </div>

        <div style={{display: 'flex', gap: '16px'}}>
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />

          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <Button variant="contained" color="primary" type="submit">
          Add
        </Button>
      </form>
    </>
  );
}
