import React, {useEffect, useState} from 'react';
import {API, Storage, Auth} from 'aws-amplify';
import data from '../../src/data.json';
import {Row, Col, Card} from 'react-bootstrap';
import './style.css';
import Header from '../components/Header';
import AddToCart from './addToCart';
import Sidebar from '../components/Sidebar';
import {toast} from 'react-toastify';
import bottle from './bottle.png';
import {Skeleton} from '@mui/material';

function App() {
  const [jsonData, setJsonData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    category: [],
    country: [],
    size: [],
    mevushal: [],
  });
  const [user, setUser] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});
  const [checked, setChecked] = useState([]);
  let [totalPrice, setTotalPrice] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [cartItem, setCartItem] = useState([]);

  useEffect(() => {
    setOriginalData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    const updatedFilteredData = originalData.filter((item) => {
      for (const key in filterCriteria) {
        if (
          filterCriteria[key].length > 0 &&
          !filterCriteria[key].includes(item[key])
        ) {
          return false;
        }
      }
      return true;
    });
    setFilteredData(updatedFilteredData);
  }, [filterCriteria, originalData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataProduct = await API.get('product', '/product');
        setJsonData(dataProduct.data);
        const response = await Storage.list('', {level: 'public'});
        const imageKeys = response.results;

        const imgList = await Promise.all(
          imageKeys.map(async (imageKey) => {
            const url = await Storage.get(imageKey.key);
            return {key: imageKey.key, url};
          })
        );

        const updatedArray = dataProduct.data.map((obj) => {
          const matchingImg = imgList.find((img) => obj.image === img.key);

          if (matchingImg) {
            return {...obj, url: matchingImg.url};
          }

          return obj;
        });

        setJsonData(updatedArray);
        setIsLoadingData(false);
      } catch (error) {
        console.error(error);
        setIsLoadingData(false);
      }
    };

    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const username = user.username;
        setUser(username);

        // Perform additional actions with the username
        // ...
      } catch (error) {
        console.log('Error:', error);
      }
    }

    // Call the function to get the current user's username
    getCurrentUser();

    fetchData();
  }, []);

  const handleImageLoad = (url) => {
    setImageLoadingStatus((prevStatus) => ({...prevStatus, [url]: true}));
  };

  useEffect(() => {
    const updatedFilteredData = jsonData.filter((item) => {
      for (const key in filterCriteria) {
        if (
          filterCriteria[key].length > 0 &&
          !filterCriteria[key].includes(item[key])
        ) {
          return false;
        }
      }
      return true;
    });
    setFilteredData(updatedFilteredData);
  }, [jsonData, filterCriteria]);

  const handleFilterChange = (key, value, checked) => {
    setFilterCriteria((prevCriteria) => {
      const updatedCriteria = {...prevCriteria};
      if (checked) {
        updatedCriteria[key] = [...updatedCriteria[key], value];
      } else {
        updatedCriteria[key] = updatedCriteria[key].filter(
          (item) => item !== value
        );
      }
      return updatedCriteria;
    });

    // Update filterData when all checkboxes are unchecked
    const allFiltersEmpty = Object.values(filterCriteria).every(
      (filterArray) => filterArray.length === 0
    );
    if (allFiltersEmpty) {
      setFilterData([]);
    }
  };

  const incNum = (data, e, value, quantity, index) => {
    const updateArr = [...data];
    if (updateArr[index].count == 0) {
      cartItem.push(updateArr[index]);
    }
    if (updateArr[index].count > quantity - 1) {
      toast.error('Product quantity should not exceed the available stock!');
      return false;
    } else {
      updateArr[index].count = updateArr[index].count += 1;

      setOriginalData(updateArr);
      setFilterData(updateArr);
      setTotalPrice(totalPrice + value);
    }

    function uniqueById(items) {
      const set = new Set();
      return items.filter((item) => {
        const isDuplicate = set.has(item.id);
        set.add(item.id);
        return !isDuplicate;
      });
    }
  };
  const decNum = (data, e, value, index) => {
    setTotalPrice(totalPrice - value);
    const updateArr = [...data];
    updateArr[index].count = updateArr[index].count -= 1;
    const deletedArr = cartItem.filter((item) => item.count !== 0);
    setCartItem(deletedArr);
    setOriginalData(updateArr);
  };
  const handleChange = async (data, e, value, index) => {
    console.log('back', e.target.value);
    let currentVal = e.target.value;
    // let updatedVal = itemArr * value;
    // updatedVal = index > 0 ? (totalPrice += value) : updatedVal;
    // setTotalPrice(updatedVal);
    const updateArr = [...data];
    updateArr[index].count = e.target.value;
    setOriginalData(updateArr);

    setTotalPrice(totalPrice + value);
    // setLocalCount(updateArr[index].count)
  };
  const findMatches = (updatedList) => {
    // console.log("updatedList===", updatedList)
    let matched = jsonData.filter((key) => {
      for (let i = 0; i < updatedList.length; i++) {
        if (
          updatedList.length > 1 &&
          updatedList.includes(key.category) &&
          updatedList.includes(key.country)
        ) {
          return true;
        } else if (
          updatedList.length > 1 &&
          (updatedList[i] !== key.category || updatedList[i] !== key.country)
        ) {
          return false;
        } else if (
          updatedList[i] == key.category ||
          updatedList[i] == key.country ||
          updatedList[i] == key.region
        ) {
          console.log('iam in elase');
          return true;
        }
        // if (
        //   // updatedList[i] == key.color ||
        //   // updatedList[i] == key.country ||
        //   // updatedList[i] == key.Region

        //   updatedList.length>1 && updatedList.includes(key.color) && updatedList.includes(key.color)
        // )
        //   return true;
      }
      return false;
    });
    // console.log("match", matched)
    // // console.log("pushed==", matched);
    setFilterData(matched);
    return matched;
  };
  const popMatches = (updatedList) => {
    let matched = jsonData.filter((key) => {
      for (let i = 0; i < updatedList.length; i++) {
        if (updatedList[i] === key.color || updatedList[i] === key.country)
          return true;
      }
      return false;
    });
    // console.log("poped==", matched);
    setFilterData(matched);
    return matched;
  };

  return (
    <>
      <Header filterData={cartItem} filterCheked={filterData} />
      <div className="App">
        <header
          className="App-header"
          style={{
            marginTop: '55px',
          }}
        >
          <div className="bg-dark">
            <Row className="mt-5 px-5 w-100">
              <Col md={2} lg={2} xs={12}>
                <Sidebar
                  filterData={cartItem}
                  totalPrice={totalPrice}
                  handleFilter={handleFilterChange}
                />
              </Col>
              <Col md={6} lg={6}>
                <p
                  style={{
                    color: 'white',
                    marginTop: '26px',
                  }}
                >
                  Welcome member No {user}. We wish you a wonderful day!
                </p>

                {isLoadingData ? (
                  <h3 className="empty-text text-white d-flex align-items-center justify-content-center h-75"></h3>
                ) : filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <Card className="shadow mb-3 mt-5" key={index}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            {!imageLoadingStatus[item.url] ? (
                              <img
                                src={bottle}
                                alt="Loading..."
                                style={{width: '190px', height: '266px'}}
                              />
                            ) : null}
                            <img
                              src={item.url}
                              alt="bottle"
                              style={{
                                width: '190px',
                                height: '266px',
                                display: imageLoadingStatus[item.url]
                                  ? 'block'
                                  : 'none',
                              }}
                              onLoad={() => handleImageLoad(item.url)}
                            />
                          </div>
                          <div className="col-12 col-md-8">
                            <div className="row">
                              <div className="col-12 col-md-6 mt-5">
                                <h4>{item.title}</h4>
                                <span>{item.category}</span>&nbsp;&nbsp;
                                <span>
                                  {item.country} {item.region} <br />
                                  {item.size && item.size + ' '}
                                  {item.mevushal === 'true'
                                    ? 'Mevushal'
                                    : 'Not Mevushal'}
                                  <p
                                    className="ms-auto margin"
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '1.2rem',
                                    }}
                                  >
                                    {item.price + '$'}
                                  </p>
                                </span>
                              </div>
                              <div className="col-12 col-md-6 d-flex flex-column justify-content-end mb-5">
                                <div className="row">
                                  <div className="col-12">
                                    <p className="available mb-1">
                                      {item.quantity > 12
                                        ? 'Available 12+'
                                        : `Available ${item.quantity}`}
                                    </p>
                                  </div>
                                </div>
                                <div className="row align-items-end">
                                  <div className="col-12 d-flex justify-content-start">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      disabled={item.count === 0}
                                      onClick={(e) =>
                                        decNum(
                                          filteredData,
                                          e,
                                          item.price,
                                          index
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <p className="mb-0 mx-2">{item.count}</p>
                                    <button
                                      className="btn btn-info btn-sm"
                                      onClick={(e) =>
                                        incNum(
                                          filteredData,
                                          e,
                                          item.price,
                                          item.quantity,
                                          index
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12">
                                <p className="text-start">{item.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <h3 className="empty-text text-white d-flex align-items-center justify-content-center h-75">
                    No bottles available with the selected filters
                  </h3>
                )}
              </Col>
              <Col md={4} lg={4}>
                <AddToCart
                  filterData={cartItem}
                  jsonData={filterData}
                  setJsonData={setJsonData}
                  totalPrice={totalPrice}
                  setCartItem={setCartItem}
                  setTotalPrice={setTotalPrice}
                />
              </Col>
            </Row>
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
