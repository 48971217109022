/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "order",
            "endpoint": "https://7zajbdk3v5.execute-api.ca-central-1.amazonaws.com/dev",
            "region": "ca-central-1"
        },
        {
            "name": "product",
            "endpoint": "https://b5e4hsx82c.execute-api.ca-central-1.amazonaws.com/dev",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:3e162a4a-099f-4eda-8d9b-3ac5d8f64d0b",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_PFBgq9TzB",
    "aws_user_pools_web_client_id": "1fec2ppu9vk6bg4js1ppeldnmh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lechaim92624-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "order-dev",
            "region": "ca-central-1"
        },
        {
            "tableName": "product-dev",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;
