import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import './style.css';
import {Row, Col, Card} from 'react-bootstrap';
import Product from './Product';
import User from './User';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');
  const [username, setUsername] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUsername(user.username);
    };

    fetchData().catch(console.error);
  }, []);

  if (username !== '001' && username !== '00') {
    return (
      <div>
        <p style={{color: 'white'}}>You don't have access here</p>
      </div>
    );
  }

  return (
    <div
      className="container-fluid h-100 overflow-auto"
      style={{marginTop: '20px', width: '100%', height: '100%'}}
    >
      <div className="row h-100">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Card className="shadow" style={{background: 'white'}}>
            <Card.Body>
              <Box>
                <TabContext value={value}>
                  <Box>
                    <TabList onChange={handleChange}>
                      <Tab label="Product" value="1" />
                      <Tab label="Order List" value="2" />
                      <Tab label="Add user" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Product />
                  </TabPanel>
                  <TabPanel value="2"></TabPanel>
                  <TabPanel value="3">
                    <User />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
